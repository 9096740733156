const plugins = [
    {
        name: 'base-library',
        urlGlobalVariable: 'baseLibraryUrl',
        fallbackVersion: '1.2.1',
        configVersionKey: 'baseLibraryVersion',
    },
];

const initPluginUrl = (plugin) => {
    const urlFromSessionStorage = sessionStorage.getItem(plugin.urlGlobalVariable);
    console.debug(plugin);
    if (urlFromSessionStorage) {
        console.debug(
            `Using url from sessionStorage. plugin: ${plugin.name}, url: ${urlFromSessionStorage}`
        );
        window[plugin.urlGlobalVariable] = urlFromSessionStorage;
    } else {
        const { appManifest, plugins, config } = window.proofpoint.appShell;

        // using baseSourceUrl from appContext plugin for now.
        // try to use config base url, then fallback to appContext base url
        const baseSourceUrl =
            config.PLUGIN_BASE_SOURCE_URL || plugins[appManifest.appContext].baseSourceUrl;

        // fallback to current deployed version so we don't have to time the release
        const version = config[plugin.configVersionKey] ?? plugin.fallbackVersion;
        window[plugin.urlGlobalVariable] = `${baseSourceUrl}/${plugin.name}/${version}`;
    }
};

const initAllPluginUrls = () => {
    plugins.forEach(initPluginUrl);
};

export default initAllPluginUrls;
